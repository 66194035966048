import { MessageView } from '@uag/react-core/dist/components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import image from '../start/assets/expired.svg';

export const RequestNotFoundExpired = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <MessageView
            actionText={t('startNewRegistration')}
            description={t('requestNotFoundExpiredDescription')}
            image={image}
            title={t('requestNotFoundExpired')}
            fullWidth
            onActionClick={() => navigate('/')}
        />
    );
};
