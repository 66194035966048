import { OidcUserStatus } from '@axa-fr/react-oidc';
import { useGetRequests } from '@shared/api/queries/requests/requests';
import { LoadingAnimation, useOidcContext } from '@uag/react-core';
import { Navigate } from 'react-router';

export const Home = () => {
    const { userLoadingState } = useOidcContext();

    const { data: requestsResponse, isLoading: isRequestsLoading } = useGetRequests();

    if (userLoadingState === OidcUserStatus.Loading || isRequestsLoading) {
        return <LoadingAnimation />;
    }

    const requests = requestsResponse?.data;

    if (!requests || requests.length <= 0) {
        return <Navigate to="/selectCustomer" />;
    }

    if (requests.length === 1) {
        return <Navigate to={`/request/${requests[0].id}`} />;
    }

    return <Navigate to="/manageRequests" />;
};
