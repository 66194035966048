import { secureRoute } from '@uag/react-core';
import { Route, Routes } from 'react-router';

import { ManageRequests } from 'app/request/ManageRequests';
import { OnboardingFrame as RequestFrame } from 'app/request/OnboardingFrame';
import { SelectCustomerView } from 'app/request/SelectCustomerView';
import { Home } from './Home';
import { NotFound } from './NotFound';
import { Request } from './request/Request';
import { RequestNotFoundExpired } from './request/RequestNotFound';
import { Invitation } from './start/Invitation';
import { StartLayoutFrame } from './start/StartLayoutFrame';
import { Unauthorized } from './Unauthorized';

export const AppRoutes = () => {
    return (
        <Routes>
            <Route Component={secureRoute(RequestFrame)} path="/">
                <Route Component={secureRoute(Home)} path="" />
                <Route Component={secureRoute(ManageRequests)} path="manageRequests" />
                <Route Component={secureRoute(Request)} path="request/:requestId" />
            </Route>
            <Route Component={StartLayoutFrame}>
                <Route Component={secureRoute(SelectCustomerView)} path="selectCustomer" />
                <Route Component={secureRoute(Invitation)} path="invitation/:requestId" />
                <Route Component={secureRoute(RequestNotFoundExpired)} path="request/not-found-expired" />
            </Route>
            <Route Component={Unauthorized} path="/unauthorized" />
            <Route Component={NotFound} path="*" />
        </Routes>
    );
};
